import React from 'react';
import { Col, Container, ResponsiveEmbed, Row } from 'react-bootstrap';
import { Disqus, Footer, Layout, Navbar, PageHeader, Sponsor } from '@components';
import { videoFallback } from '@variables';
import { graphql } from 'gatsby';
import { GatsbyImage, getImage, getSrc } from 'gatsby-plugin-image';
import { uniqueId } from 'lodash';
import '@scss/sponsor-room-page.scss';

const SupporterPage = ({ data }) => {
  const { sponsor } = data.postgres;

  return <Layout className="supporter-page">
    <Navbar />
    <PageHeader
      title={sponsor?.name}
      paddingBottom="74px"
      superTextComponent={<p>{sponsor?.year} {sponsor?.level}</p>}
    />

    <Container style={{ marginBottom: `1rem`, marginTop: `3rem` }}>
      <Row>
        <Col className="about">
          <h3>About Us</h3>
          {
            sponsor?.about.length > 1 ?
              <ul>
                {sponsor?.about.map(point => <li key={uniqueId()}>{point}</li>)}
              </ul> :
              <p>
                {sponsor?.about[0]}
              </p>
          }
        </Col>
      </Row>
      <Row className="mb-4">
        <Col>
          <p>
            <strong>Visit Our Website: </strong>
            <a href={sponsor?.website} target="_blank" rel="noopener noreferrer">
              {sponsor?.website}
            </a>
          </p>
          {
            !!sponsor?.available &&
              <p>
                <strong>We are available to chat below from:</strong> {sponsor?.available}
              </p>
          }
        </Col>
      </Row>
      <Row className="justify-content-center align-items-center mb-4">
        {
          !!sponsor?.videoUrl &&
            <Col lg={6} style={{ marginBottom: `1rem` }}>
              <ResponsiveEmbed aspectRatio="16by9">
                <iframe
                  title={sponsor?.name}
                  src={videoFallback ? sponsor?.backupVideoUrl : sponsor?.videoUrl}
                  className="embed-responsive-item"
                  allow="autoplay; fullscreen"
                  allowFullScreen
                />
              </ResponsiveEmbed>
            </Col>
        }
        <Col lg={6} style={{ marginBottom: `1rem`, textAlign: `center` }}>
          {
            sponsor?.image ?
              <img
                className="image"
                src={getSrc(sponsor?.imageFile)}
                alt={sponsor?.name} /> :
              <GatsbyImage
                imgClassName="logo"
                image={getImage(sponsor?.logoFile)}
                alt={sponsor?.name}
              />
          }
        </Col>
      </Row>
      <Disqus
        identifier={`sponsor-${sponsor?.id}`}
        title={sponsor?.name}
        pageLocation={`/sponsors/${sponsor?.id}`}
      />
    </Container>

    <Sponsor />
    <Footer />

  </Layout>;
};

export const query = graphql`
  query SponsorRoomPageQuery($sponsorId: Int!) {
    postgres {
      sponsor: sponsorById(id: $sponsorId) {
        available
        about
        backupVideoUrl
        hasSponsorRoom
        id
        image
        imageFile {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        level
        logo
        logoFile {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        name
        videoUrl
        website
        year
      }
    }
  }
`;

export default SupporterPage;
